<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_referee_system',
                }"
                >裁判系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>总决选有组别-晋级赛</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row>
          <el-col :span="12"><el-page-header @back="goBack"></el-page-header></el-col>
          <el-col :span="12" align="right">
            <el-button type="warning" plain size="small" icon="el-icon-info" @click="descVisible = true">使用说明</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">条件筛选</span>
                        </el-col>
                    </el-row>
                    <el-form
                        ref="queryFormRef"
                        label-width="80px"
                        :model="queryForm"
                        :rules="queryFormRule"
                    >
                        <el-row :gutter="10">
                          <el-col :span="12">
                                <el-form-item label="赛季" prop="season">
                                    <el-select
                                        placeholder="请选择赛季"
                                        v-model="queryForm.season"
                                    >
                                        <el-option
                                            v-for="item in dict_season"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="组别" prop="level">
                                    <el-select
                                        v-model="queryForm.level"
                                        @change="levelChange"
                                        ref="gameTypeRef"
                                    >
                                        <el-option
                                            v-for="item in dict_group"
                                            :key="item.dictCode"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12" v-if="roomList.length">
                            <el-form-item label="考场" prop="roomId" style="margin-bottom: 0;">
                              <el-select
                                v-model="queryForm.roomId"
                                ref="gameTypeRef"
                              >
                                <el-option
                                  v-for="item in roomList"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="roomList.length ? 12 : 24" align="right">
                            <el-button type="primary" @click="getMatchInfo()">确 认</el-button>
                          </el-col>
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="12">
              <el-card style="margin-top: 15px; height: 194px;">
                <el-row>
                  <el-col :span="10">
                    <span class="title_class">考场管理</span>
                  </el-col>
                  <el-col :span="14" align="right">
                    <el-button type="primary" plain size="small" @click="showRoomPop" :disabled="!matchInfoShow">分配考场</el-button>
                    <el-button type="danger" plain size="small" :disabled="!matchInfoShow" @click="resetRooms">重置</el-button>
                  </el-col>
                </el-row>
                <div class="default" v-if="!roomList.length">
                  <el-empty description="暂未分配考场" :image-size="50"></el-empty>
                </div>
                <el-row v-else class="room-con">
                  <el-col :span="8" v-for="(room,i) in roomList" :key="i">
                    <div class="room-item" @click="handleToRoom(room)" :class="queryForm.roomId === room.id ? 'room-active' : ''">
                      <div class="room-name">
                        <i class="el-icon-s-flag"></i> {{ room.name }}
                      </div>
                      <div class="room-num">{{ room.roomUserNum }}人</div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
        </el-row>
        <el-card style="margin-top: 15px" v-if="queryForm.level !== '' && matchInfoShow">
            <el-row class="">
                <el-col :span="10">
                    <span class="title_class">裁判功能
                      <!-- 未设置晋级人数时显示 -->
                      <span  v-if="matchInfoShow && !matchInfo.length && totalNum !== ''">
                        <span class="s1">总人数：<span class="s2">{{ totalNum }}</span></span>
                        <span class="s1">已签到：<span class="s2">{{ signInNum }}</span></span>
                        <span class="s1">未签到：<span class="s2">{{ noSignInNum }}</span></span>
                        <!-- <span class="s1">预晋级：未设置</span> -->
                      </span>
                      <!-- 比赛开始后 -->
                      <span v-if="matchInfoShow && matchInfo.length">
                        <span class="s1">预晋级：<span class="s2">{{ statisticsInfo.setPassNum }}</span></span>
                        <span class="s1">已晋级：<span class="s2">{{ statisticsInfo.passedNum }}</span></span>
                      </span>
                    </span>
                </el-col>
                <el-col :span="14" align="right">
                    <el-button
                        type="primary"
                        plain
                        icon="el-icon-document-checked"
                        :disabled="!matchInfoShow"
                        @click="showSignInInfoDialog()"
                        size="small"
                        >签到情况</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        icon="el-icon-setting"
                        :disabled="!matchInfoShow"
                        @click="showSetPromotionNumberDialog"
                        size="small"
                        >设置晋级人数</el-button
                    >
                    <el-button
                        v-if="matchInfoShow && matchInfo.length"
                        type="primary"
                        plain
                        icon="el-icon-s-data"
                        :disabled="!matchInfoShow"
                        @click="showRankInfoDialog()"
                        size="small"
                        >晋级名单</el-button
                    >
                    <el-button
                        v-if="matchInfoShow && matchInfo.length"
                        type="danger"
                        plain
                        icon="el-icon-refresh"
                        :disabled="!matchInfoShow"
                        @click="resetAllRoundsDialogVisable = true"
                        size="small"
                        >重置比赛</el-button
                    >
                    <el-button
                        type="danger"
                        plain
                        icon="el-icon-back"
                        size="small"
                        :disabled="!matchInfoShow"
                        @click="resetMatch()"
                        v-if="showBackButton"
                        >比赛回退</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-tabs
            style="margin-top: 15px"
            class="cpxt"
            v-if="matchInfoShow && matchInfo.length"
            type="border-card"
            v-model="currentMatchInfoIndex"
            @tab-click="getMatchInfo('select')"
        >
            <el-tab-pane
                v-for="(item, i) in matchInfo"
                :label="'round' + item.round + '-' + item.roundName"
                :name="i + ''"
                :key="i"
            >
                <template slot="label">
                    <div
                        class="usual-tab-card"
                        :class="'usual-tab-card' + item.roundType"
                    >
                        {{ "round" + item.round + "-" + item.roundName }}
                    </div>
                </template>
                <el-row :gutter="20" class="tab-headview">
                    <el-col
                        :span="14"
                        style="font-size: 16px"
                        v-if="item.roundType !== -110"
                    >
                        <!-- <span style="margin-left: 30px; color: #4096ff"
                            >预晋级:{{ statisticsInfo.setPassNum }}人</span
                        >
                        <span style="margin-left: 30px; color: #67c23a"
                            >已晋级:{{ statisticsInfo.passedNum }}人</span
                        >
                        <span style="margin-left: 30px; color: #e6a23c"
                            >本轮人数:{{ statisticsInfo.curNum }}</span
                        >
                        <span style="margin-left: 30px; color: #67c23a"
                            >本轮晋级:{{ statisticsInfo.curPass }}</span
                        >
                        <span style="margin-left: 30px; color: #F56C6C"
                            >本轮淘汰:{{ statisticsInfo.curOut }}</span
                        >
                        <span style="margin-left: 30px; color: #e6a23c"
                            >本轮:{{ statisticsInfo.curWait }}</span
                        > -->
                        <div>
                          <span class="s1">当前考场：<span class="s2">{{ statisticsInfo.curNum }}</span></span>
                          <span class="s1">晋级：<span class="s2">{{ statisticsInfo.curPass }}</span></span>
                          <span class="s1">淘汰：<span class="s2">{{ statisticsInfo.curOut }}</span></span>
                          <span class="s1">待比赛：<span class="s2">{{ statisticsInfo.curWait }}</span></span>
                        </div>
                        <div v-if="roomList.length > 1">
                          <span class="s1">所有考场：<span class="s2">{{ currentMatchInfo.total }}</span></span>
                          <span class="s1">晋级：<span class="s2">{{ currentMatchInfo.promotedNum }}</span></span>
                          <span class="s1">淘汰：<span class="s2">{{ currentMatchInfo.notPromotedNum }}</span></span>
                          <span class="s1">待比赛：<span class="s2">{{ currentMatchInfo.notSetPromotedNum }}</span></span>
                        </div>
                    </el-col>
                    <el-col
                        :span="14"
                        style="font-size: 16px"
                        v-if="item.roundType === -110"
                    >
                        <!-- <span style="margin-left: 20px; color: #fdba01"
                            >参赛人数:{{ statisticsInfo.userNum }}</span
                        >
                        <span style="margin-left: 20px; color: #67c23a"
                            >晋级人数:{{ statisticsInfo.promotedNum }}</span
                        >
                        <span style="margin-left: 20px; color: #F56C6C"
                            >淘汰人数:{{ statisticsInfo.notPromotedNum }}</span
                        > -->
                        <span class="s1">参赛人数：<span class="s2">{{ statisticsInfo.userNum }}</span></span>
                        <span class="s1">晋级：<span class="s2">{{ statisticsInfo.promotedNum }}</span></span>
                        <span class="s1">淘汰：<span class="s2">{{ statisticsInfo.notPromotedNum }}</span></span>
                    </el-col>
                    <el-col
                        :span="10"
                        align="right"
                    >
                      <el-button
                        type="primary"
                        plain
                        icon="el-icon-download"
                        :disabled="!matchInfoShow"
                        @click="downloadInfoList()"
                        size="medium"
                        >导出本轮名单</el-button
                      >
                      <el-button
                        type="primary"
                        size="medium"
                        @click="showSetPromotionDialog(item)"
                        v-if="showSetPromotionButton && item.roundType !== -110"
                        >设置晋级</el-button
                      >
                    </el-col>
                </el-row>
                <el-table
                    height="100%"
                    header-cell-class-name="tableHeaderStyle"
                    :data="showPlayers"
                    border
                    :row-class-name="tableRowClassName"
                >
                    <el-table-column type="index" label="#"></el-table-column>
                    <el-table-column
                        label="学校"
                        prop="schoolName"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="年级"
                        prop="grade"
                        width="120"
                        align="center"
                        :formatter="gradeFormat"
                    ></el-table-column>
                    <el-table-column
                        label="上场顺序/抽签"
                        width="120"
                        prop="competitionNumber"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="选手姓名"
                        width="180"
                        prop="userName"
                        align="center"
                    >
                        <template scope="scope">
                            <span class="nationalnum"
                                >{{ scope.row.userName }}
                                {{
                                    scope.row.countryReserve === true
                                        ? "(保留)"
                                        : ""
                                }}</span
                            >
                        </template></el-table-column
                    >
                    <el-table-column
                        label="国赛号码牌"
                        width="120"
                        prop="nationalnum"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <div class="nationalnum" style="color: red;">
                                {{ scope.row.nationalnum }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="晋级"
                        v-if="item.roundType !== -110"
                        width="160px"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-checkbox
                                border
                                label="晋级"
                                size="small"
                                :class="
                                    scope.row.promoted === '1'
                                        ? 'promoted-btn'
                                        : ''
                                "
                                v-model="scope.row.promoted"
                                true-label="1"
                                false-label=""
                                :key="scope.row.playerId"
                                @change="upDateStatisticsInfo(item, scope.row)"
                            >
                            </el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="淘汰"
                        v-if="item.roundType !== -110"
                        width="160px"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-checkbox
                                border
                                label="淘汰"
                                size="small"
                                :class="
                                    scope.row.promoted === '0'
                                        ? 'eliminate-btn'
                                        : ''
                                "
                                v-model="scope.row.promoted"
                                true-label="0"
                                false-label=""
                                :key="scope.row.playerId"
                                :style="{ '--fill-color': '#F56C6C' }"
                                @change="upDateStatisticsInfo(item, scope.row)"
                            >
                            </el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="晋级状态"
                        v-if="item.roundType === -110"
                        width="100"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-tag
                                type="success"
                                v-if="scope.row.promoted === '1'"
                                >晋级</el-tag
                            >
                            <el-tag
                                type="danger"
                                v-else-if="scope.row.promoted === '0'"
                                >淘汰</el-tag
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <!-- 查看签到情况的Dialog -->
        <el-dialog
            :title="'签到情况（已签到 ' + signInInfo.length + ' 人）'"
            :visible.sync="signInInfoDialogVisable"
            width="60%"
        >
            <el-table
                style="margin-top: 0"
                header-cell-class-name="tableHeaderStyle"
                :data="signInInfo"
                border
            >
                <el-table-column type="index"></el-table-column>
                <el-table-column
                    label="学校"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column label="姓名" prop="name">
                    <template scope="scope">
                        <span
                            >{{ scope.row.userName }}
                            {{
                                scope.row.countryReserve === true
                                    ? "(保留)"
                                    : ""
                            }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="年级" prop="grade" :formatter="gradeFormat"></el-table-column>
                <el-table-column
                    label="上场顺序/抽签"
                    prop="competitionNumber"
                >
                    <!-- <template scope="scope">
                        <span style="color: green; font-size: 18px">{{
                            scope.row.competitionNumber
                        }}</span>
                    </template> -->
                </el-table-column>
                <el-table-column
                    label="性别"
                    prop="gender"
                    :formatter="genderFormatter"
                ></el-table-column>
            </el-table>
        </el-dialog>
        <!-- 设置晋级人数的Dialog -->
        <el-dialog
            title="设置晋级人数"
            :visible.sync="setPromotionNumberDialogVisable"
            width="500px"
        >
            <el-form label-width="80px">
                <el-form-item label="晋级人数">
                    <el-input
                        type="number"
                        v-model="promotionNum"
                        :disabled="!setPromotionNumberEnable"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span
                slot="footer"
                class="dialog-footer"
                v-if="setPromotionNumberEnable"
            >
                <el-button type="primary" @click="setPromotionNumber()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 设置晋级的Dialog -->
        <el-dialog
            title="设置晋级"
            :visible.sync="setPromotionDialogVisible"
            width="50%"
        >
            <div style="font-size: 18px">
                本轮为：{{ currentTurnName }}，参赛人数：{{
                    currentTurnUserNum
                }}人
            </div>
            <div style="color: #f56c6c; font-size: 18px; margin-top: 40px">
                淘汰选手：{{ setPromotionTipsOut.length }}人
                {{ rankTitleStr }}
            </div>
            <el-row>
                <el-col
                    v-for="(item, index) in setPromotionTipsOut"
                    :key="index"
                    :span="6"
                >
                    <span style="color: #f56c6c"
                        ><span style="font-weight: bold">{{
                            item.competitionNumber
                        }}</span
                        >号-{{ item.userName }}</span
                    >
                </el-col>
            </el-row>
            <div style="color: #67c23a; margin-top: 40px; font-size: 18px">
                晋级选手：{{ setPromotionTipsPass.length }}人
                {{ rankTitleStrJ }}
            </div>
            <el-row>
                <el-col
                    v-for="(item, index) in setPromotionTipsPass"
                    :key="index"
                    :span="6"
                >
                    <span style="color: #67c23a"
                        ><span style="font-weight: bold">{{
                            item.competitionNumber
                        }}</span
                        >号-{{ item.userName }}</span
                    >
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button
                    type="danger"
                    v-if="setPromotionTipsPass.length > 1"
                    @click="promotTied()"
                    >并列晋级</el-button
                >
                <el-button @click="setPromotionDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="setPromotion()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 查看晋级名单的Dialog -->
        <el-dialog
            title="晋级名单"
            :visible.sync="showRealTimeRankingDialogVisable"
            width="800px"
        >
            <el-table
                style="margin-top: 0"
                header-cell-class-name="tableHeaderStyle"
                :data="rankInfo"
                border
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="姓名" prop="userName"></el-table-column>
                <el-table-column
                    label="国赛号码牌"
                    prop="nationalnum"
                >
                <template slot-scope="scope">
                    <div class="nationalnum" style="color: red;">
                        {{ scope.row.nationalnum }}
                    </div>
                </template>
                </el-table-column>
                <el-table-column
                    label="上场顺序/抽签"
                    prop="competitionNumber"
                >
                </el-table-column>
                <el-table-column
                    width="70px"
                    label="性别"
                    prop="sex"
                    :formatter="genderFormatter"
                ></el-table-column>
                <el-table-column
                    label="学校"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column label="年级" prop="grade" :formatter="gradeFormat"></el-table-column>
                <el-table-column label="晋级状态" align="center">
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.promoted === '0'"
                            size="mini"
                            >淘汰</el-tag
                        >
                        <el-tag
                            type="success"
                            v-else-if="scope.row.promoted === '1'"
                            size="mini"
                            >晋级</el-tag
                        >
                        <el-tag
                            type="info"
                            v-else-if="scope.row.promoted === '-1'"
                            size="mini"
                            >待比赛</el-tag
                        >
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 重置所有轮次 -->
        <el-dialog
            title="重置所有轮次"
            :visible.sync="resetAllRoundsDialogVisable"
            width="40%"
        >
            <el-form label-width="80px">
                <el-form-item label="输入密码">
                    <el-input type="text" v-model="password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="resetAllRounds()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 使用说明 -->
        <el-dialog
            title="使用说明"
            :visible.sync="descVisible"
            width="800px"
        >
          <div class="tab-desc">
              1、请先选择该场比赛的比赛组别，并点击<span style="color: rgb(64, 158, 255);">【确认】</span>按钮。
          </div>
          <div class="tab-desc">
              2、分配考场，可自由设置本组别考场数量、每个考场人数。
          </div>
          <div class="tab-desc">
              3、点击考场名称，查看考场内选手信息，可添加、删除、移动选手。
          </div>
          <div class="tab-desc">
              4、切换考场：在条件筛选中选择考场进行切换。
          </div>
          <div class="tab-desc">
              5、确认<span style="color: red;">签到结束</span>后，设置晋级人数，系统自动生成第一轮比赛。
          </div>
          <div class="tab-desc">
              6、如遇上一轮比赛设置错误的情况，可点击<span style="color: red;">【比赛回退】</span>按钮回退到上一轮比赛。
          </div>
        </el-dialog>
        <!-- 分配考场 -->
        <el-dialog
            title="分配考场"
            :visible.sync="roomVisible"
            width="800px"
        >
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
              <template slot="label">
                赛季
              </template>
              {{ formatSeason(queryForm.season) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                组别
              </template>
              {{ formatLevel(queryForm.level) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                参赛人数
              </template>
              <span style="color: #4096ff;font-size: 18px; font-weight: bold;">
                {{ totalNum }}
              </span> 人
            </el-descriptions-item>
          </el-descriptions>
          <el-form label-width="120px" style="margin-top: 20px;">
            <el-form-item label="是否均分">
              <el-radio-group v-model="roomForm.avgType" @change="avgChangeFn">
                <el-radio :label="true">均分</el-radio>
                <el-radio :label="false">自定义考场人数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="考场数量">
              <el-input-number v-model="roomForm.roomNum" :min="1" @change="roomNumChangeFn"></el-input-number>
            </el-form-item>
            <div v-if="roomForm.avgType">
              <el-form-item label="每考场人数">
                <el-input-number v-model="singleNum" :min="1"></el-input-number>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item :label="'考场'+ (i+1) +'人数'" v-for="(item, i) in roomForm.avgList" :key="i">
                <el-input-number v-model="roomForm.avgList[i]" :min="1"></el-input-number>
              </el-form-item>
            </div>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveRoomInfo">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  nmpGetPromotionNum, nmpSetPromotionNum, nmpCreateNextFinalMatch, nmpGetUserList, nmpResetMatch,
  nmpResetting, nmpGetMatchWinners, getSignInList, nmpSetPromotionPoint, nmpSetAbreastPromotion, nmpGetTotal,
  nmpGetRoomByRound, nmpSelectRound, nmpAllocationRooms, nmpResetRooms
} from '@/http/api'
export default {
  data () {
    return {
      descVisible: false,
      roomVisible: false,
      totalNum: '', // 参赛总人数
      signInNum: '', // 已签到人数
      noSignInNum: '', // 未签到人数
      singleNum: '', // 每考场人数
      roomList: [],
      roomForm: {
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.$route.query.raceSchedule,
        season: this.$chnEngStatusCode.defaultSeason,
        roomNum: '',
        level: '',
        avgType: true,
        avgList: ['']
      },
      rankTitleStrJ: '',
      rankTitleStr: '',
      password: '',
      showPlayers: [],
      showRealTimeRankingDialogVisable: false,
      matchInfoShow: false,
      dict_group: this.$userInfo.dict_group(),
      e_sex: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.e_sex = value }),
      dict_grade: this.$userInfo.getGradeData(),
      queryFormRule: {
        level: [{ required: true, message: '请选择', trigger: 'change' }],
        season: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      queryForm: {
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.$route.query.raceSchedule,
        season: this.$chnEngStatusCode.defaultSeason,
        level: '',
        roomId: '' // 选中的考场id

      },
      resetAllRoundsDialogVisable: false,
      matchInfo: [],
      signInInfoDialogVisable: false,
      setPromotionNumberDialogVisable: false,
      promotionNum: 0,
      setPromotionNumberEnable: false,
      signInInfo: [],
      rankInfo: [],
      currentMatchInfoIndex: '',
      statisticsInfo: {
        setPassNum: '',
        passedNum: '',
        curNum: '',
        curPass: '',
        curOut: '',
        curWait: ''
      },
      dict_season: [],
      setPromotionDialogVisible: false,
      setPromotionTipsOut: [],
      setPromotionTipsPass: [],
      setPromotionData: [],
      showSchedule: {},
      currentMatchInfo: {}
    }
  },
  created () {
    this.getSeason()
  },
  methods: {
    getSeason () {
      this.$userInfo.getDataList(this, 't_season').then(value => {
        this.dict_season = value
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.roundType !== -110) {
        if (row.promoted === '0') {
          return 'warning-row'
        } else if (row.promoted === '1') {
          return 'success-row'
        } else {
          return ''
        }
      }
      return ''
    },
    goBack () {
      this.$router.back()
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    showRoomPop () {
      this.roomForm.level = this.queryForm.level
      const form = {
        activityType: this.roomForm.activityType,
        season: this.roomForm.season,
        raceSchedule: this.roomForm.raceSchedule,
        level: this.queryForm.level
      }
      if (!this.matchInfo.length) {
        form.selectAll = true
      }
      nmpGetTotal(form).then(res => {
        if (res.code === 200) {
          if (res.data.totalNum) {
            this.roomVisible = true
            this.totalNum = res.data.totalNum
            this.roomForm.avgType = true
            this.roomForm.avgList = []
            this.roomForm.roomNum = 1
            this.singleNum = res.data.totalNum
          } else {
            this.$message.warning('暂无参赛人员')
          }
        }
      })
    },
    gradeFormat (data) {
      return data.grade ? this.dict_grade.find(item => item.dictValue === data.grade).dictLabel : ''
    },
    handleToRoom (item) {
      this.$router.push(`/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_group_promote_rooms?id=${item.id}&name=${item.name}&level=${this.queryForm.level}&round=${!this.matchInfo.length ? 1 : this.matchInfo[this.currentMatchInfoIndex].round}`)
    },
    levelChange () {
      this.matchInfoShow = false
      this.queryForm.roomId = ''
    },
    // 获取考场列表
    getRoomList (round) {
      nmpGetRoomByRound({
        season: this.queryForm.season,
        activityType: this.queryForm.activityType,
        raceSchedule: this.queryForm.raceSchedule,
        level: this.queryForm.level,
        round: round || (!this.matchInfo.length ? 1 : this.matchInfo[this.matchInfo.length - 1].round)
      }).then(res => {
        if (res.code === 200) {
          this.roomList = res.data
          if (!this.queryForm.roomId && res.data.length) {
            this.queryForm.roomId = res.data[0].id
          }

          if (this.matchInfo.length !== 0 && !round) {
            this.currentMatchInfoIndex = this.matchInfo.length - 1 + ''
            this.getRoundPlayerInfo(this.matchInfo[this.currentMatchInfoIndex])
          }
        }
      })
    },
    // 重置考场
    resetRooms () {
      this.$confirm('确认重置考场?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        nmpResetRooms({
          season: this.queryForm.season,
          activityType: this.queryForm.activityType,
          raceSchedule: this.queryForm.raceSchedule,
          level: this.queryForm.level,
          round: !this.matchInfo.length ? 1 : this.matchInfo[this.matchInfo.length - 1].round
        }).then(res => {
          if (res.code === 200) {
            this.getRoomList()
          }
        })
      }).catch(() => {})
    },
    // 获取本组别人数
    getTotalNum (selectAll) {
      nmpGetTotal({
        activityType: this.queryForm.activityType,
        season: this.queryForm.season,
        raceSchedule: this.queryForm.raceSchedule,
        level: this.queryForm.level,
        selectAll
      }).then(res => {
        if (res.code === 200) {
          this.totalNum = res.data.totalNum
          this.signInNum = res.data.signInNum
          this.noSignInNum = res.data.noSignInNum
        }
      })
    },
    avgChangeFn () {
      this.roomForm.avgList = []
      this.roomForm.roomNum = 1
      if (!this.roomForm.avgType) {
        this.roomForm.avgList.push(this.totalNum)
      }
    },
    roomNumChangeFn () {
      if (this.roomForm.avgType) {
        // 均分
        this.singleNum = Math.ceil(this.totalNum / this.roomForm.roomNum)
      } else {
        // 自定义
        this.roomForm.avgList = new Array(Number(this.roomForm.roomNum)).fill(Math.ceil(this.totalNum / this.roomForm.roomNum))
      }
    },
    saveRoomInfo () {
      const round = !this.matchInfo.length ? 1 : this.matchInfo[this.matchInfo.length - 1].round
      nmpAllocationRooms({ ...this.roomForm, ...{ round } }).then(res => {
        if (res.code === 200) {
          this.roomVisible = false
          this.getRoomList()
        }
      })
    },
    nationalnumFormatter (num) {
      return this.$xcUtils.addZero(num)
    },
    formatSeason (season) {
      return this.dict_season.length ? this.dict_season.find(item => item.dictValue === season).dictLabel : ''
    },
    formatLevel (level) {
      return level !== '' && level !== undefined && level > -1 ? this.dict_group.find(item => item.dictValue === level).dictLabel : ''
    },
    // 获取轮次信息
    getMatchInfo (flag) {
      if (flag === 'select') {
        this.getRoundPlayerInfo(this.matchInfo[this.currentMatchInfoIndex])
        this.getRoomList(this.matchInfo[this.currentMatchInfoIndex].round)
      } else {
        this.$refs.queryFormRef.validate(async valid => {
          if (!valid) return
          // console.log(this.queryForm)
          nmpSelectRound(this.queryForm).then((res) => {
            this.matchInfo = res.data
            this.getRoomList()
            if (!this.matchInfo.length) {
              this.getTotalNum(true)
            }
            // if (this.matchInfo.length !== 0) {
            //   this.currentMatchInfoIndex = this.matchInfo.length - 1 + ''
            //   this.getRoundPlayerInfo(this.matchInfo[this.currentMatchInfoIndex])
            // }
            this.matchInfoShow = true
          }).catch((err) => {
            console.log('返回的err', err)
          })
        })
      }
    },
    showSignInInfoDialog () {
      this.queryForm.signIn = true
      getSignInList(this.queryForm).then((res) => {
        // res.data.forEach(item => {
        //   item.competitionNumber = this.nationalnumFormatter(item.competitionNumber)
        // })
        this.signInInfo = res.data
        this.signInInfoDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 查看晋级名单
    showRankInfoDialog () {
      nmpGetMatchWinners(this.queryForm).then((res) => {
        this.rankInfo = res.data
        this.rankInfo.forEach((item, index) => {
          item.promoted = item.promoted + ''
          // item.competitionNumber = this.nationalnumFormatter(item.competitionNumber)
        })
        this.showRealTimeRankingDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },

    showSetPromotionNumberDialog () {
      nmpGetPromotionNum(this.queryForm).then((res) => {
        this.promotionNum = res.data.promotionNum
        if (this.promotionNum < 1) {
          this.promotionNum = 0
          this.setPromotionNumberEnable = true
        } else {
          this.setPromotionNumberEnable = false
        }
        this.setPromotionNumberDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    setPromotionNumber () {
      if (this.promotionNum === 0) {
        return this.$message.warning('晋级人数不能为0')
      }
      this.queryForm.promotionNum = this.promotionNum
      nmpSetPromotionNum(this.queryForm).then((res) => {
        this.$message.success('设置成功')
        this.getMatchInfo()
        this.setPromotionNumberDialogVisable = false
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.e_sex) {
        if (Object.hasOwnProperty.call(this.e_sex, key)) {
          const element = this.e_sex[key]
          console.log('element.dictValue', element.dictValue)
          if (element.dictValue + '' === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    showSetPromotionDialog (data) {
      this.setPromotionDataRequest(data)
    },
    // 处理晋级数据
    setPromotionDataRequest (data) {
      console.log('this.showPlayers', this.showPlayers)
      // 检查是否所有选手都设置了晋级状态
      var temp = []
      this.showPlayers.forEach(element => {
        if (element.promoted === '-1' || element.promoted === '') {
          temp.push(element)
        }
      })
      if (temp.length > 0) {
        var tempStr = ''
        temp.forEach(element => {
          tempStr += element.competitionNumber + '号-' + element.userName + '<br>'
          // tempStr += `${element.competitionNumber}号-${element.userName}`
        })
        return this.$alert(tempStr, '存在选手未设置晋级', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true
        })
      }
      // 再次确认本轮晋级情况
      // 初始化数据
      this.setPromotionTipsOut = []
      this.setPromotionTipsPass = []
      this.showPlayers.forEach(element => {
        if (element.promoted === '1') {
          this.setPromotionTipsPass.push(element)
        }
        if (element.promoted === '0') {
          this.setPromotionTipsOut.push(element)
        }
      })
      this.setPromotionData = data
      this.getEliminationRankingInformation()
    },

    // 获取淘汰排名信息
    getEliminationRankingInformation () {
      // var playerIds = [] // 晋级比赛选手id
      // this.showPlayers.forEach(element => {
      //   if (element.promoted === '1') {
      //     playerIds.push(element.playerId)
      //   }
      // })
      // this.queryForm.playerIds = playerIds
      // this.queryForm.finalMatchId = this.setPromotionData.id
      this.setPromotionDialogVisible = true
      // nmpGetPromotionRanking(this.queryForm).then((res) => {
      //   if (res.data.notPromotionRanking) {
      //     this.rankTitleStr = '获得名次：第' + res.data.notPromotionRanking + '名'
      //   } else {
      //     this.rankTitleStr = ''
      //   }
      //   if (res.data.promotionRanking) {
      //     this.rankTitleStrJ = '获得名次：第' + res.data.promotionRanking + '名'
      //   } else {
      //     this.rankTitleStrJ = ''
      //   }
      //   this.setPromotionDialogVisible = true
      // }).catch((err) => {
      //   console.log('返回的err', err)
      // })
    },
    setPromotion (flag) {
      this.queryForm.finalMatchId = this.setPromotionData.id
      if (flag === 'promotTied') {
        nmpSetAbreastPromotion(this.queryForm).then((res) => {
          this.$curUtils.closeLoading(this)
          this.$message.success('设置晋级成功')
          this.setPromotionDialogVisible = false
          this.getMatchInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        // finalMatchId
        nmpCreateNextFinalMatch({
          season: this.queryForm.season,
          activityType: this.queryForm.activityType,
          raceSchedule: this.queryForm.raceSchedule,
          level: this.queryForm.level,
          finalMatchId: this.queryForm.finalMatchId
        }).then((res) => {
          this.$curUtils.closeLoading(this)
          this.$message.success('设置晋级成功')
          this.setPromotionDialogVisible = false
          this.getMatchInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    upDateStatisticsInfo (data, rowData) {
      console.log('data', rowData)
      this.statisticsInfo.setPassNum = data.finalMathPromotedNum
      this.statisticsInfo.passedNum = data.winnwePromotedNum
      this.statisticsInfo.curNum = 0
      this.statisticsInfo.curPass = 0
      this.statisticsInfo.curOut = 0
      this.statisticsInfo.curWait = 0
      this.showPlayers.forEach(element => {
        this.statisticsInfo.curNum++
        if (element.promoted === '1') {
          this.statisticsInfo.curPass++
        } else if (element.promoted === '0') {
          this.statisticsInfo.curOut++
        } else {
          this.statisticsInfo.curWait++
        }
      })
      if (rowData) {
        this.setPromotedData(rowData)
      }
    },
    async setPromotedData (rowData) {
      if (rowData.promoted !== '' && rowData.promoted !== '-1') {
        console.log('this.queryForm', this.queryForm)
        nmpSetPromotionPoint({
          ...this.queryForm,
          ...{
            playerId: rowData.playerId,
            promoted: rowData.promoted
          }
        }, true).then((res) => {
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    // 获取轮次下指定选手列表
    getRoundPlayerInfo (showSchedule) {
      this.currentMatchInfo = this.matchInfo[this.currentMatchInfoIndex]
      this.currentMatchInfo.total = Math.ceil(this.currentMatchInfo.notSetPromotedNum + this.currentMatchInfo.notPromotedNum + this.currentMatchInfo.promotedNum)
      console.log('showSchedule', showSchedule)
      this.showSchedule = showSchedule
      if (showSchedule.roundType === -110) { // 晋级名单单独处理
        nmpGetMatchWinners(this.queryForm).then((res) => {
          this.showPlayers = res.data.map(item => {
            item.promoted = String(item.promoted)
            return item
          })
          this.statisticsInfo.setPassNum = showSchedule.finalMathPromotedNum
          this.statisticsInfo.passedNum = showSchedule.winnwePromotedNum
          this.statisticsInfo.userNum = res.data.length
          this.statisticsInfo.promotedNum = res.data.filter(item => item.promoted === '1').length
          this.statisticsInfo.notPromotedNum = res.data.filter(item => item.promoted === '0').length
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        const data = {
          finalMatchId: showSchedule.id,
          season: this.queryForm.season,
          activityType: this.queryForm.activityType,
          raceSchedule: this.queryForm.raceSchedule,
          cityRoomId: this.queryForm.roomId,
          level: this.queryForm.level
        }
        nmpGetUserList(data).then((res) => {
          this.showPlayers = res.data.map(item => {
            item.promoted = String(item.promoted)
            return item
          })
          this.upDateStatisticsInfo(showSchedule)
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    matchOver () {
      this.$confirm('比赛结束将由“已晋级选手”和“本轮晋级选手”直接生成晋级名单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setPromotion('matchOver')
      })
    },
    resetMatch () {
      this.$confirm('比赛回退将重新返回上一轮比赛未设置晋级的状态, 是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        nmpResetMatch(this.queryForm).then((res) => {
          this.$message.success('比赛回退成功')
          this.getMatchInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    promotTied () {
      this.$confirm('并列晋级将由“本轮晋级选手”并列排名晋级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setPromotion('promotTied')
      })
    },
    // 下载签到表
    downloadInfoList () {
      var downLoadUrl = ''
      if (this.matchInfo[this.currentMatchInfoIndex].roundType === -110) {
        downLoadUrl = this.$env.baseIP + 'nationalMatchPromote/exportMatchWinners'
      } else {
        downLoadUrl = this.$env.baseIP + 'nationalMatchPromote/excelUserByRound'
      }
      this.queryForm.finalMatchId = this.matchInfo[this.currentMatchInfoIndex].id
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    },
    // 重置所有轮次
    resetAllRounds () {
      if (this.password !== '1qazxsw2cz') {
        return this.$message.error('密码错误！')
      }
      this.queryForm.password = this.password
      nmpResetting(this.queryForm).then((res) => {
        this.resetAllRoundsDialogVisable = false
        this.getMatchInfo()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  },
  computed: {
    showSetPromotionButton () {
      // var schduleArray = []
      // for (let index = 0; index < this.matchInfo.length; index++) {
      //   const element = this.matchInfo[index]
      //   if (element.roundType !== -110) {
      //     schduleArray.push(element)
      //   }
      // }
      return (Number(this.currentMatchInfoIndex) === this.matchInfo.length - 1 && this.matchInfo[this.matchInfo.length - 1].roundType !== -110)
    },
    showBackButton () {
      // var schduleArray = []
      // for (let index = 0; index < this.matchInfo.length; index++) {
      //   const element = this.matchInfo[index]
      //   if (element.roundType !== -110) {
      //     schduleArray.push(element)
      //   }
      // }
      return (Number(this.currentMatchInfoIndex) === this.matchInfo.length - 1) && (Number(this.currentMatchInfoIndex) !== 0)
    },
    currentTurnName () {
      if (this.matchInfo[this.currentMatchInfoIndex] !== undefined) {
        return this.matchInfo[this.currentMatchInfoIndex].roundName
      }
      return ''
    },
    currentTurnUserNum () {
      if (this.showPlayers) {
        return this.showPlayers.length
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.el-tab-pane {
    height: calc(100vh - 110px);
    overflow-y: auto;
}
.el-table {
    // margin-top: 56px;
    // z-index: 99;
}
.tab-headview {
    // position: absolute;
    // top: 0;
    // width: 100%;
    // z-index: 100;
    // background-color: white;
    // padding: 12px 0px;
    // line-height: 36px;
}
.nationalnum {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    letter-spacing: 1px;
}
.guanjun{
  color: #ffae5d;
}
.yajun{
  color: #cccccc;
}
.jijun{
color:#b86d29
}
.tab-desc{
    font-size: 14px;
    margin-bottom: 1px;
  }
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-right-color: transparent;
    border-left-color: transparent;
    height: 50px;
    line-height: 56px;
    // background: #409EFF;
    color: #fff;
    font-size: 18px;
    border-radius: 20px 20px 0 0;
    .usual-tab-card {
        background: #409eff;
        padding-left: 24px;
        padding-right: 24px;
    }
}
.default ::v-deep {
  .el-empty__description{
    margin-top: 10px;
  }
  .el-empty{
    padding-top: 20px;
    padding-bottom: 0;
  }
}
.tip{
  font-size: 14px;
  color: #909399;
  margin-left: 10px;
  margin-right: 30px;
}
.title_class{
  line-height: 32px;
}
.s1{
  font-size: 14px;
  color: #909399;
  // margin-right: 10px;
  margin-left: 10px;
}
.s2{
  font-size: 18px;
  font-weight: bold;
  color: #409eff;
  width: 40px;
  display: inline-block;
}
.room-con{
  overflow: hidden;
}
.room-name{
  font-size: 15px;
  height: 48px;
  background-color: #0277ff;
  color: #fff;
  line-height: 48px;
  padding: 0 10px;
  // border-radius: 4px 0 0 4px;
  border-top-left-radius: 18px;
}
.room-num{
  font-size: 18px;
  font-weight: bold;
}
.room-item{
  color: #0277ff;
  border: 1px solid #0277ff;
  background-color: #f4f5fc;
  border-top-left-radius: 20px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 10px;
  padding-right: 10px;
  cursor: pointer;
  &.room-active{
    color: #fff;
    background-color: #0277ff;
  }
  &:hover{
    box-shadow: 0 2px 10px 0 rgba(2, 119, 255, .2);
  }
}
</style>
